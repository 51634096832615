//
//

export default {
  //=================================================
  name: 'simple-editor',
  //=================================================
  props: {
    columns: {
      type: Array,
      default: () => ([])
    },
    rows: {
      type: Array,
      default: () => ([])
    },
    categories: {
      type: Array,
      default: () => ([])
    },
    loading: Boolean,
    theadClass: String,
    thClass: String,
    tbodyClass: String,
    trClass: String,
    tdClass: String,
    theadClassFn: Function,
    thClassFn: Function,
    tbodyClassFn: Function,
    trClassFn: Function,
    tdClassFn: Function,
    inputClass: String,
    inputClassFn: Function
  },
  //=================================================
  data() {
    return {
      fullscreen: false,
      search: '',
      sortBy: null,
      sortByType: null,
      sortDesc: false,
      localRows: [],
      headers: [
        { name: 'id', type: 'text', id: true, width: 50 },
        { name: 'lp', type: 'number', width: 60 },
        { name: 'kategoria', type: 'text', width: 200, datalist: 'datalist-kategorie' },
        { name: 'nazwa', type: 'text', width: 250 },
        { name: 'opis', type: 'text', width: 250 },
        { name: 'cena', type: 'number', width: 80 },
        { name: 'aktywne', type: 'boolean', width: 80 },
      ],
      rowTemplate: {
        id: -1,
        lp: 0,
        kategoria: '',
        nazwa: '',
        opis: '',
        cena: 0,
        aktywne: true,
      },
      newRow: {
        id: -1,
        lp: 0,
        kategoria: '',
        nazwa: '',
        opis: '',
        cena: 0,
        aktywne: true,
      }
    }
  },
  //=================================================
  watch: {
    //===================
    rows() {
      this.localRows = this.localRows.filter(r => {
        return this.rows.find(row => row.id == r.id) == false;
      });
    }
    //===================
  },
  //=================================================
  computed: {
    //===================
    allRows() {
      return [...this.localRows, ...this.rows]
    },
    //===================
    rowsFiltered() {
      return this.allRows
        .filter(row => {
          return JSON.stringify(Object.values(row)).toLowerCase().indexOf(this.search.toLowerCase()) != -1
        })
        // .filter(row => {
        //   if (this.showQuickFilters == false) return true
        //   return Object.keys(row).every(key => {
        //     let filter = this.filters[key];
        //     if (!filter) return true
        //     return `${row[key]}`.toLowerCase().indexOf(`${filter}`.toLowerCase()) != -1;
        //   })
        // })
        .sort((a, b) => {
          if (this.sortBy) {
            let x = a[this.sortBy], 
                y = b[this.sortBy]

            if (this.sortByType == 'number') {
              return (x > y) ? 1 : (x < y ? -1 : 0);
            }

            return `${x}`.localeCompare(`${y}`) * (this.sortDesc ? -1 : 1)
          }
        });
    },
    //===================
  },
  //=================================================
  methods: {
    //===================
    handleHeaderClick(header) {
      console.log('[simple-editor][handleHeaderClick]');
      if (this.sortBy == header.name) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
      }

      this.sortBy = header.name;
      this.sortByType = header.type;
    },
    //===================
    saveNewRow() {
      console.log('[simple-editor][saveNewRow]');
      let newRow = Object.assign({}, this.newRow);
      this.newRow = Object.assign({}, this.rowTemplate);
      this.localRows.unshift(newRow);
      this.$emit('save-row', newRow);
    },
    //===================
    saveRow(row) {
      console.log('[simple-editor][saveRow]', row);
      this.$emit('save-row', row);
    },
    //===================
    removeRow(row) {
      console.log('[simple-editor][removeRow]', row);
      let index = this.localRows.findIndex(r => r.id === row.id);
      
      if (index >= 0) {
        this.localRows.splice(index, 1);
        return;
      }

      this.$emit('remove-row', row);
    },
    //===================
  },
  //=================================================
}

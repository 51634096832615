var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-editor", attrs: { id: "menu-editor" } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.dataset.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm mr-1",
            class: "btn-" + (_vm.tab == "simple" ? "primary" : "dark"),
            on: {
              click: function($event) {
                _vm.tab = "simple"
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-fw",
              class: "fa-" + (_vm.tab == "simple" ? "check-" : "") + "circle"
            }),
            _vm._v(" Prosty\r\n    ")
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm mr-1",
            class: "btn-" + (_vm.tab == "tree" ? "primary" : "dark"),
            on: {
              click: function($event) {
                _vm.tab = "tree"
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-fw",
              class: "fa-" + (_vm.tab == "tree" ? "check-" : "") + "circle"
            }),
            _vm._v(" Drzewo\r\n    ")
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm mr-1",
            class: "btn-" + (_vm.tab == "excel" ? "primary" : "dark"),
            on: {
              click: function($event) {
                _vm.tab = "excel"
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-fw",
              class: "fa-" + (_vm.tab == "excel" ? "check-" : "") + "circle"
            }),
            _vm._v(" Excel\r\n    ")
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "float-right" }, [
          _vm.info && _vm.info.message
            ? _c(
                "div",
                {
                  staticClass: "messages alert mb-0 py-1",
                  class: "alert-" + _vm.info.type
                },
                [
                  _c("i", {
                    staticClass: "fa fa-fw",
                    class: "fa-" + _vm.info.icon
                  }),
                  _vm._v(
                    "\r\n        " + _vm._s(_vm.info.message) + "\r\n        "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "clickable text-dark float-right ml-2",
                      on: {
                        click: function($event) {
                          _vm.info.message = null
                        }
                      }
                    },
                    [_vm._v("X")]
                  )
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.tab == "excel"
        ? _c(
            "div",
            [
              _c("XlsTable", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.allRows,
                  categories: _vm.categories,
                  loading: _vm.loading
                },
                on: {
                  refresh: _vm.refresh,
                  "save-row": _vm.saveItem,
                  "remove-row": _vm.removeItem
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == "simple"
        ? _c(
            "div",
            [
              _c("SimpleEditor", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.allRows,
                  categories: _vm.categories,
                  loading: _vm.loading
                },
                on: {
                  refresh: _vm.refresh,
                  "save-row": _vm.saveItem,
                  "remove-row": _vm.removeItem
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == "tree"
        ? _c("div", [
            _c("div", { staticClass: "row no-gutters mb-2" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _c("div", { staticClass: "input-group input-group-sm" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newCategory,
                        expression: "newCategory"
                      }
                    ],
                    staticClass:
                      "form-control form-control-sm bg-dark text-white",
                    attrs: { placeholder: "Nowa kategoria" },
                    domProps: { value: _vm.newCategory },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newCategory = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-dark btn-sm border",
                        on: { click: _vm.addCategory }
                      },
                      [_c("i", { staticClass: "fa fa-fw fa-plus" })]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4" }, [
                _c("div", { staticClass: "px-2" }, [
                  _c("div", { staticClass: "input-group input-group-sm" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search,
                          expression: "search"
                        }
                      ],
                      staticClass:
                        "form-control form-control-sm bg-dark text-white",
                      attrs: { placeholder: "Szukaj..." },
                      domProps: { value: _vm.search },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.search = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-dark border",
                          on: {
                            click: function($event) {
                              _vm.search = ""
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-times" })]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2 text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-dark btn-sm border",
                    on: { click: _vm.refresh }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-fw fa-refresh",
                      class: { "fa-spin": _vm.loading }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "bar-menu-table" },
              [
                _vm._l(_vm.filteredMenu, function(kategoria, k) {
                  return [
                    _c(
                      "tr",
                      {
                        key: "kategoria-" + k,
                        staticClass: "bar-menu-category-row",
                        class: {
                          loading: kategoria.__loading,
                          new: kategoria.id < 0,
                          hidden: !kategoria.aktywne
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "bar-menu-lp text-center",
                            staticStyle: { width: "30px" }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "clickable",
                                class:
                                  "text-" +
                                  (kategoria.aktywne ? "success" : "danger"),
                                on: {
                                  click: function($event) {
                                    return _vm.toggleCategoryState(kategoria)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-fw fa-eye",
                                  class:
                                    "fa-" +
                                    (kategoria.aktywne ? "eye" : "eye-slash")
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bar-menu-lp text-center",
                            staticStyle: { width: "40px" }
                          },
                          [
                            !kategoria._lp
                              ? _c(
                                  "div",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.openCategoryField(
                                          kategoria,
                                          "lp"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(kategoria.lp))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            kategoria._lp
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: kategoria.lp,
                                      expression: "kategoria.lp"
                                    },
                                    { name: "focus", rawName: "v-focus" }
                                  ],
                                  staticClass:
                                    "koza-menu-input kategoria-lp-input",
                                  domProps: { value: kategoria.lp },
                                  on: {
                                    keyup: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "esc",
                                            27,
                                            $event.key,
                                            ["Esc", "Escape"]
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.closeCategoryField(
                                          kategoria,
                                          "lp"
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.saveCategory(kategoria)
                                      }
                                    ],
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        kategoria,
                                        "lp",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bar-menu-category",
                            staticStyle: { width: "500px" }
                          },
                          [
                            _c(
                              "div",
                              [
                                !kategoria._nazwa
                                  ? _c(
                                      "big",
                                      {
                                        style: "color: " + kategoria.kolor,
                                        on: {
                                          click: function($event) {
                                            return _vm.openCategoryField(
                                              kategoria,
                                              "nazwa"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(kategoria.nazwa))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                kategoria._nazwa
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: kategoria.nazwa,
                                          expression: "kategoria.nazwa"
                                        },
                                        { name: "focus", rawName: "v-focus" }
                                      ],
                                      staticClass:
                                        "koza-menu-input kategoria-nazwa-input",
                                      domProps: { value: kategoria.nazwa },
                                      on: {
                                        keyup: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "esc",
                                                27,
                                                $event.key,
                                                ["Esc", "Escape"]
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.closeCategoryField(
                                              kategoria,
                                              "nazwa"
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.saveCategory(kategoria)
                                          }
                                        ],
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            kategoria,
                                            "nazwa",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(kategoria.html)
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !kategoria._opis_kategorii
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bar-menu-category-desc",
                                    class: {
                                      "text-muted": !kategoria.opis_kategorii
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openCategoryField(
                                          kategoria,
                                          "opis_kategorii"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\r\n            " +
                                        _vm._s(
                                          kategoria.opis_kategorii || "+ opis"
                                        ) +
                                        "\r\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            kategoria._opis_kategorii
                              ? _c(
                                  "div",
                                  { staticClass: "bar-menu-category-desc" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: kategoria.opis_kategorii,
                                          expression: "kategoria.opis_kategorii"
                                        },
                                        { name: "focus", rawName: "v-focus" }
                                      ],
                                      staticClass:
                                        "koza-menu-input kategoria-opis-input",
                                      domProps: {
                                        value: kategoria.opis_kategorii
                                      },
                                      on: {
                                        keyup: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "esc",
                                                27,
                                                $event.key,
                                                ["Esc", "Escape"]
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.closeCategoryField(
                                              kategoria,
                                              "opis_kategorii"
                                            )
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.saveCategory(kategoria)
                                          }
                                        ],
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            kategoria,
                                            "opis_kategorii",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { width: "70px" }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "clickable p-1 text-light mx-1",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleOpenState(kategoria)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-caret-down",
                                  class: { "fa-rotate-180": kategoria.__open }
                                }),
                                _vm._v(
                                  "\r\n            " +
                                    _vm._s(kategoria.pozycje.length) +
                                    "\r\n          "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          !kategoria.__loading
                            ? _c(
                                "a",
                                {
                                  staticClass: "clickable p-1 text-light mx-1",
                                  on: {
                                    click: function($event) {
                                      return _vm.saveCategory(kategoria)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-save" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !kategoria.__loading
                            ? _c(
                                "a",
                                {
                                  staticClass: "clickable p-1 text-light mx-1",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeCategory(kategoria)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !kategoria.__loading
                            ? _c(
                                "a",
                                {
                                  staticClass: "clickable p-1 text-light mx-1",
                                  on: {
                                    click: function($event) {
                                      return _vm.addItem(kategoria)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-plus" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          kategoria.__loading
                            ? _c(
                                "a",
                                {
                                  staticClass: "clickable p-1 text-light mx-1"
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-refresh fa-spin"
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(kategoria.pozycje, function(pozycja) {
                      return kategoria.__open
                        ? _c("MenuEditorRow", {
                            key: "pozycja_" + pozycja.id,
                            attrs: {
                              pozycja: pozycja,
                              kategoria: kategoria,
                              hidden: !kategoria.aktywne
                            },
                            on: {
                              "update:pozycja": function($event) {
                                pozycja = $event
                              },
                              save: _vm.saveItem,
                              remove: _vm.removeItem
                            }
                          })
                        : _vm._e()
                    })
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text text-white bg-dark" }, [
        _c("i", { staticClass: "fa fa-fw fa-search" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
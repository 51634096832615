var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type == "boolean"
    ? _c(
        "button",
        { staticClass: "v-input v-input--boolean", on: { click: _vm.toggle } },
        [
          _c("i", {
            staticClass: "fa fa-fw",
            class: _vm.local_value ? "fa-check" : "fa-times"
          })
        ]
      )
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.local_value,
            expression: "local_value"
          }
        ],
        staticClass: "v-input",
        attrs: { list: _vm.datalist },
        domProps: { value: _vm.local_value },
        on: {
          keydown: function($event) {
            return _vm.$emit("keydown", $event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.local_value = $event.target.value
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./BarMenuEditorRow.vhtml?vue&type=template&id=6d11cfeb&"
import script from "./BarMenuEditorRow.vue?vue&type=script&lang=js&"
export * from "./BarMenuEditorRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wamp64\\www\\madeyski\\j-php-engine\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d11cfeb')) {
      api.createRecord('6d11cfeb', component.options)
    } else {
      api.reload('6d11cfeb', component.options)
    }
    module.hot.accept("./BarMenuEditorRow.vhtml?vue&type=template&id=6d11cfeb&", function () {
      api.rerender('6d11cfeb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BarMenuEditorRow.vue"
export default component.exports
//
//
//
//
//
//
//
//

import BarMenuEditor from '../components/BarMenuEditor.vue';

export default {
  //=====================================================
  name: 'App',
  //=====================================================
  computed: {
    //==============
    apiPage() {
      const config = window.$appConfig || {};

      return config.menuEditorPage || 'bar-menu-editor';
    },
    //==============
  },
  //=====================================================
  components: { BarMenuEditor },
  //=====================================================
}

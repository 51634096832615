var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass: "bar-menu-item-row",
      class: {
        loading: _vm.local.__loading,
        new: _vm.local.id < 0,
        hidden: _vm.hidden || !_vm.local.aktywne
      }
    },
    [
      _c(
        "td",
        {
          staticClass: "bar-menu-lp text-center",
          staticStyle: { width: "30px" }
        },
        [
          _c(
            "a",
            {
              staticClass: "clickable",
              class: "text-" + (_vm.local.aktywne ? "success" : "danger"),
              on: {
                click: function($event) {
                  return _vm.toggleState()
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-fw fa-eye",
                class: "fa-" + (_vm.local.aktywne ? "eye" : "eye-slash")
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "bar-menu-item-lp text-center",
          staticStyle: { width: "40px" }
        },
        [
          !_vm.local._lp
            ? _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.open("lp")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.local.lp))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.local._lp
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local.lp,
                    expression: "local.lp"
                  },
                  { name: "focus", rawName: "v-focus" }
                ],
                staticClass: "koza-menu-input pozycja-lp-input text-center",
                domProps: { value: _vm.local.lp },
                on: {
                  keyup: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape"
                        ])
                      ) {
                        return null
                      }
                      return _vm.reset("lp")
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.save("lp")
                    }
                  ],
                  blur: function($event) {
                    return _vm.exit("nazwa")
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.local, "lp", $event.target.value)
                  }
                }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "bar-menu-item-title" }, [
        !_vm.local._nazwa
          ? _c(
              "div",
              {
                on: {
                  click: function($event) {
                    return _vm.open("nazwa")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.local.nazwa))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.local._nazwa
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local.nazwa,
                  expression: "local.nazwa"
                },
                { name: "focus", rawName: "v-focus" }
              ],
              staticClass: "koza-menu-input pozycja-nazwa-input",
              domProps: { value: _vm.local.nazwa },
              on: {
                keyup: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.reset("nazwa")
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save("nazwa")
                  }
                ],
                blur: function($event) {
                  return _vm.exit("nazwa")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.local, "nazwa", $event.target.value)
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "bar-menu-item-price px-2",
          staticStyle: { width: "70px" }
        },
        [
          !_vm.local._cena
            ? _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.open("cena")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.local.cena))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.local._cena
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local.cena,
                    expression: "local.cena"
                  },
                  { name: "focus", rawName: "v-focus" }
                ],
                staticClass: "koza-menu-input pozycja-cena-input text-right",
                domProps: { value: _vm.local.cena },
                on: {
                  keyup: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape"
                        ])
                      ) {
                        return null
                      }
                      return _vm.reset("cena")
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.save("cena")
                    }
                  ],
                  blur: function($event) {
                    return _vm.exit("nazwa")
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.local, "cena", $event.target.value)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.local.img
            ? _c("div", { staticClass: "bar-menu-item-image" }, [
                _c("img", {
                  staticClass: "bar-menu-item-img",
                  attrs: { src: _vm.local.img.src, alt: "Obrazek" }
                })
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "text-center" }, [
        !_vm.local.__loading
          ? _c(
              "a",
              {
                staticClass: "clickable p-1 text-light mx-1",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-save" })]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.local.__loading
          ? _c(
              "a",
              {
                staticClass: "clickable p-1 text-light mx-1",
                on: {
                  click: function($event) {
                    return _vm.remove()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-trash" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.local.__loading
          ? _c("a", { staticClass: "clickable p-1 text-light mx-1" }, [
              _c("i", { staticClass: "fa fa-refresh fa-spin" })
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "xls-table-wrapper", class: { fullscreen: _vm.fullscreen } },
    [
      _c(
        "datalist",
        { attrs: { id: "datalist-kategorie" } },
        _vm._l(_vm.categories, function(category, index) {
          return _c("option", {
            key: "cat-" + index,
            domProps: { value: category }
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "xls-table-wrapper--menu" }, [
        _c(
          "button",
          { staticClass: "xls--button", on: { click: _vm.toggleImport } },
          [
            _c("i", { staticClass: "fa fa-fw fa-clipboard" }),
            _vm._v("\r\n      Import\r\n    ")
          ]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search"
            }
          ],
          staticClass: "xls--menu--input",
          attrs: { type: "text", placeholder: "Szukaj..." },
          domProps: { value: _vm.search },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "xls--button",
            on: {
              click: function($event) {
                _vm.showQuickFilters = !_vm.showQuickFilters
              }
            }
          },
          [
            _c("i", { staticClass: "fa fa-fw fa-search" }),
            _vm._v("\r\n      Filtry\r\n      "),
            _c("i", {
              staticClass: "fa fa-fw",
              class: _vm.showQuickFilters ? "fa-check" : "fa-times"
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "menu-right" }, [
          _c(
            "button",
            {
              staticClass: "xls--button",
              on: {
                click: function($event) {
                  return _vm.$emit("refresh")
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-fw fa-refresh",
                class: { "fa-spin": _vm.loading }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "xls--button", on: { click: _vm.toggleFullscreen } },
            [
              _c("i", {
                staticClass: "fa fa-fw",
                class: _vm.fullscreen
                  ? "fa-window-minimize"
                  : "fa-window-maximize"
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.fullscreen
        ? _c("div", { staticClass: "xls-table-wrapper--fullscreen-margin" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "xls-table-wrapper--container" }, [
        _c("table", { staticClass: "xls-table" }, [
          _c(
            "thead",
            {
              staticClass: "xls--thead",
              class: [
                _vm.theadClass,
                _vm.theadClassFn ? _vm.theadClassFn() : ""
              ]
            },
            [
              _c(
                "tr",
                [
                  _c("th", { staticClass: "xls--th" }),
                  _vm._v(" "),
                  _vm._l(_vm.visibleHeaders, function(header, colIndex) {
                    return _c(
                      "th",
                      {
                        key: "th--" + colIndex,
                        staticClass: "xls--th",
                        class: [
                          _vm.getHeaderClass(header),
                          _vm.thClass,
                          _vm.thClassFn ? _vm.thClassFn() : ""
                        ],
                        style: header.width ? header.width + "px" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleHeaderClick(header)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\r\n            " +
                            _vm._s(header.name) +
                            "\r\n            "
                        ),
                        _vm.sortBy == header.name
                          ? _c("i", {
                              staticClass: "fa fa-fw",
                              class: _vm.sortDesc
                                ? "fa-sort-down"
                                : "fa-sort-up"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "xls--th--resizer",
                          class: {
                            active: _vm.headerResizer.currentHeader == header
                          },
                          on: {
                            mousedown: function($event) {
                              return _vm.startHeaderResize($event, header)
                            }
                          }
                        })
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "xls--th xls--th--menu text-center text-nowrap"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "xls--button",
                          on: {
                            click: function($event) {
                              return _vm.addRow()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-plus fa-fw" })]
                      ),
                      _vm._v(" "),
                      _vm.showColumnMenu
                        ? _c(
                            "div",
                            {
                              staticClass: "xls--column-menu",
                              class: { show: _vm.showColumnMenu }
                            },
                            _vm._l(_vm.headers, function(header, colIndex) {
                              return _c(
                                "a",
                                {
                                  key: "col--" + colIndex,
                                  staticClass: "xls--column-menu--button",
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleHeader(header)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-fw",
                                    class: header.visible
                                      ? "fa-eye"
                                      : "fa-eye-slash"
                                  }),
                                  _vm._v(
                                    "\r\n                " +
                                      _vm._s(header.name) +
                                      "\r\n              "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _vm.showQuickFilters
                ? _c(
                    "tr",
                    [
                      _c("th", { staticClass: "xls--th" }),
                      _vm._v(" "),
                      _vm._l(_vm.visibleHeaders, function(header, colIndex) {
                        return _c(
                          "th",
                          {
                            key: "th--" + colIndex + "--filter",
                            staticClass: "xls--th--filter",
                            class: [
                              _vm.getHeaderClass(header),
                              _vm.thClass,
                              _vm.thClassFn ? _vm.thClassFn() : ""
                            ],
                            style: header.width ? header.width + "px" : ""
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters[colIndex],
                                  expression: "filters[colIndex]"
                                }
                              ],
                              staticClass: "xls--th--filter--input",
                              attrs: { placeholder: "Szukaj..." },
                              domProps: { value: _vm.filters[colIndex] },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filters,
                                    colIndex,
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("th", { staticClass: "xls--th" })
                    ],
                    2
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "tbody",
            {
              staticClass: "xls--tbody",
              class: [
                _vm.tbodyClass,
                _vm.tbodyClassFn ? _vm.tbodyClassFn() : ""
              ]
            },
            _vm._l(_vm.rowsFiltered, function(row, rowIndex) {
              return _c(
                "tr",
                {
                  key: "row--" + rowIndex,
                  staticClass: "xls--tr",
                  class: [
                    _vm.getRowClass(row),
                    _vm.trClass,
                    _vm.trClassFn ? _vm.trClassFn(row) : "",
                    { new: row.id < 0 }
                  ]
                },
                [
                  _c(
                    "td",
                    {
                      staticClass: "xls--td xls--td--index",
                      on: {
                        click: function($event) {
                          return _vm.selectRow(row)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\r\n            " + _vm._s(rowIndex) + "\r\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.visibleHeaders, function(header, colIndex) {
                    return _c(
                      "td",
                      {
                        key: "cell--" + rowIndex + "--" + colIndex,
                        staticClass: "xls--td",
                        class: [
                          _vm.tdClass,
                          _vm.tdClassFn
                            ? _vm.tdClassFn(row[header.name], row)
                            : ""
                        ]
                      },
                      [
                        _c("VInput", {
                          staticClass: "xls--input",
                          class: [
                            _vm.getInputClass(header, row[header.name]),
                            _vm.inputClass,
                            header.inputClass,
                            _vm.inputClassFn
                              ? _vm.inputClassFn(row[header.name], row)
                              : ""
                          ],
                          attrs: {
                            type: header.type,
                            disabled: header.readonly,
                            datalist: header.datalist
                          },
                          on: {
                            paste: function($event) {
                              $event.preventDefault()
                              return _vm.handleCellPaste(
                                $event,
                                row,
                                header,
                                rowIndex,
                                colIndex
                              )
                            },
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.saveRow(row)
                            }
                          },
                          model: {
                            value: row[header.name],
                            callback: function($$v) {
                              _vm.$set(row, header.name, $$v)
                            },
                            expression: "row[header.name]"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "xls--td xls--td--menu text-center text-nowrap"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "xls--button",
                          on: {
                            click: function($event) {
                              return _vm.saveRow(row)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-fw",
                            class: row.__loading
                              ? "fa-refresh fa-spin"
                              : "fa-save"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "xls--button",
                          on: {
                            click: function($event) {
                              return _vm.removeRow(row)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-fw",
                            class: row.__loading
                              ? "fa-refresh fa-spin"
                              : "fa-trash"
                          })
                        ]
                      )
                    ]
                  )
                ],
                2
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm.showImport
        ? _c("div", { staticClass: "xls-table--modal xls-table--import" }, [
            _c("div", { staticClass: "p-1 text-strong mb-1" }, [
              _vm._v("Wklej dane z Excela")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "p-1" }, [
              _c(
                "button",
                {
                  staticClass: "xls--button mr-1",
                  on: {
                    click: function($event) {
                      return _vm.handleImport(false)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-fw fa-check" }),
                  _vm._v(" Importuj")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "xls--button mr-1",
                  on: {
                    click: function($event) {
                      return _vm.handleImport(true)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-fw fa-check" }),
                  _vm._v(" Importuj od nowa")
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "p-1" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.importData,
                    expression: "importData"
                  }
                ],
                attrs: { cols: "30", rows: "10" },
                domProps: { value: _vm.importData },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.importData = $event.target.value
                  }
                }
              })
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//

import Vue from 'vue';
import VInput from './vInput.vue';

const DEBUG = process.env.DEBUG || false;

export default {
  //=================================================
  name: 'xls-table',
  //=================================================
  props: {
    columns: {
      type: Array,
      default: () => ([])
    },
    rows: {
      type: Array,
      default: () => ([])
    },
    categories: {
      type: Array,
      default: () => ([])
    },
    loading: Boolean,
    theadClass: String,
    thClass: String,
    tbodyClass: String,
    trClass: String,
    tdClass: String,
    theadClassFn: Function,
    thClassFn: Function,
    tbodyClassFn: Function,
    trClassFn: Function,
    tdClassFn: Function,
    inputClass: String,
    inputClassFn: Function
  },
  //=================================================
  data() {
    return {
      fullscreen: false,
      showImport: false,
      showColumnMenu: false,
      showQuickFilters: false,
      search: '',
      sortBy: null,
      sortByType: null,
      sortDesc: false,
      headers: [],
      headerResizer: {
        currentHeader: null,
        pageX: null,
        initialWidth: null
      },
      localRows: [],
      filters: {},
      importData: `1	10	Jajeczne	Jajecznica	10
2	50	Jajeczne	Sadzone	8
`,
    }
  },
  //=================================================
  components: {
    VInput
  },
  //=================================================
  watch: {
    //===================
    columns: {
      importData: true,
      handler() {
        this.saveHeaders();
      }
    },
    //===================
    rows(newValue, oldValue) {
      if (newValue && oldValue.length == 0) {
        this.saveHeaders();
      }
      this.localRows = this.localRows.filter(r => {
        return this.rows.find(row => row.id == r.id) == false;
      });
    },
    //===================
  },
  //=================================================
  computed: {
    //===================
    allRows() {
      return [...this.localRows, ...this.rows]
    },
    //===================
    rowsFiltered() {
      return this.allRows
        .filter(row => {
          return JSON.stringify(Object.values(row)).toLowerCase().indexOf(this.search.toLowerCase()) != -1
        })
        .filter(row => {
          if (this.showQuickFilters == false) return true
          return Object.keys(row).every(key => {
            let filter = this.filters[key];
            if (!filter) return true
            return `${row[key]}`.toLowerCase().indexOf(`${filter}`.toLowerCase()) != -1;
          })
        })
        .sort((a, b) => {
          if (this.sortBy) {
            let x = a[this.sortBy], 
                y = b[this.sortBy]

            if (this.sortByType == 'number') {
              return (x > y) ? 1 : (x < y ? -1 : 0);
            }

            return `${x}`.localeCompare(`${y}`) * (this.sortDesc ? -1 : 1)
          }
        });
    },
    //===================
    visibleHeaders() {
      return this.headers.filter(h => h.visible) 
    },
    //===================
  },
  //=================================================
  methods: {
    //===================
    saveHeaders() {
      if (DEBUG) console.log('[xls-table][saveHeaders]')
      let columns = {};
      
      this.columns
      .forEach(col => {
        let column = typeof col == 'string' ? { name: col } : col;
        column.visible = column.visible == undefined ? true : column.visible;
        Vue.set(column, 'name', column.name);
        Vue.set(column, 'visible', column.visible);
        Vue.set(column, 'width', column.width);
        columns[column.name] = column;
      })

      Object.keys(this.rows[0] || {})
      .filter(col => col.startsWith('_') == false)
      .forEach(col => {
        let column = typeof col == 'string' ? { name: col } : col;
        if (!columns[column.name]) {
          column.visible = column.visible == undefined ? false : column.visible;
          Vue.set(column, 'name', column.name);
          Vue.set(column, 'visible', column.visible);
          columns[column.name] = column;
        }
        Vue.set(this.filters, column.name, '');
      });

      this.headers = Object.values(columns);
    },
    //===================
    handleHeaderClick(header) {
      if (this.sortBy == header.name) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
      }

      this.sortBy = header.name;
      this.sortByType = header.type;
    },
    //===================
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    //===================
    toggleHeader(header) {
      if (DEBUG) console.log('[xls-table][toggleHeader]', header)
      header.visible = !header.visible;
    },
    //===================
    toggleImport() {
      this.showImport = !this.showImport;
    },
    //===================
    getHeaderClass(header) {
      let classes = [];

      if (this.sortBy == header.name) {
        classes.push('text-underline');
      }

      return classes.join(' ');
    },
    //===================
    getRowClass(row) {
      let classes = [];

      if (row.__loading) {
        classes.push('loading');
      }

      if (row.__selected) {
        classes.push('selected');
      }

      return classes.join(' ');
    },
    //===================
    getInputClass(header, value) {
      let classes = [];

      if (header.type == 'number') {
        classes.push('text-right');
      }

      if (header.type == 'number' && value < 0) {
        classes.push('text-danger');
      }

      return classes.join(' ');
    },
    //===================
    selectRow(row) {
      this.allRows
        .filter(r => r.__selected)
        .forEach(r => r.__selected = false)

      Vue.set(row, '__selected', true);
    },
    //===================
    addRow() {
      if (DEBUG) console.log('[xls-table][addRow]');
      let row = {};
      this.columns.forEach(c => row[c.name] = '');
      row.id = -1 * (this.localRows.length + 1)
      this.localRows.unshift(row);
    },
    //===================
    saveRow(row) {
      if (DEBUG) console.log('[xls-table][saveRow]', row);
      this.$emit('save-row', row);
    },
    //===================
    removeRow(row) {
      if (DEBUG) console.log('[xls-table][removeRow]', row);
      let index = this.localRows.findIndex(r => r.id === row.id);
      
      if (index >= 0) {
        this.localRows.splice(index, 1);
        return;
      }

      this.$emit('remove-row', row);
    },
    //===================
    handleImport(withClear = false) {
      this.showImport = false;
      let data = this.importData.split("\n");

      if (withClear === true) {
        this.localRows = [];
      }

      data
      .filter(raw => raw.trim())
      .forEach(raw => {
        let row = {};
        let list = raw.split("\t");
        this.columns.forEach((col, colIndex) => {
          row[col.name] = list[colIndex];
        });

        row.id = -1 * (this.localRows.length + 1)
        if (row) this.localRows.push(row);
      });

      this.importData = '';
    },
    //===================
    handleCellPaste($event, row, header, rowIndex, colIndex) {
      if (DEBUG) console.log('[xls-table][handleCellPaste] e:', $event, '| row:', row, '| header:', header, '| rowIndex:', rowIndex, '| colIndex:', colIndex);
      let rows, new_rows = [], headers = [], headerIndex, lines;
      
      lines = ($event.clipboardData || window.clipboardData).getData('text') || '';
      lines = lines.split("\n").filter(l => l).map(l => l.split("\t"));

      headers = [...this.visibleHeaders];
      headerIndex = headers.findIndex(h => h.name == header.name);
      headers = headers.slice(headerIndex);

      lines.forEach(line => {
        let row = {};
        line.forEach((val, index) => {
          let col = (headers[index] || {}).name || `__${index}`;
          row[col] = val;
        });
        new_rows.push(row);
      })

      rows = this.rowsFiltered.slice(rowIndex, rowIndex + new_rows.length);

      if (DEBUG) console.log('[xls-table][handleCellPaste] new_rows:', new_rows, '| rows:', rows);

      rows.forEach((row, index) => {
        let new_row = new_rows[index] || {};
        row = Object.assign(row, new_row);
      });

    },
    //===================
    stopHeaderResize() {
      this.headerResizer.currentHeader = null;
      this.headerResizer.pageX = null;
    },
    //===================
    startHeaderResize(e, header) {
      if (DEBUG) console.log('[xls-table][startHeaderResize] e:', e, '| header:', header, '|', this.headerResizer);
      this.headerResizer.currentHeader = header;
      this.headerResizer.initialWidth = header.width || headerEl.offsetWidth;
      this.headerResizer.pageX = e.pageX;

      let headerEl = e.target.parentElement;
      header.$el = headerEl;

      if (!header.width) {
        header.width = headerEl.offsetWidth;
      }
    },
    //===================
    onHeaderResize(e) {
      if (!this.headerResizer.currentHeader) return;
      let diff = e.pageX - this.headerResizer.pageX,
          newWidth = this.headerResizer.initialWidth + diff;

      if (DEBUG) console.log('[xls-table][onHeaderResize] e.pageX:', e.pageX, '| diff:', diff, '| header.width:', this.headerResizer.currentHeader.width, '| newWidth:', newWidth);

      this.headerResizer.currentHeader.width = newWidth;
      this.headerResizer.currentHeader.$el.style.width = `${newWidth}px`;
    },
    //===================
  },
  //=================================================
  created() {
    this.saveHeaders();
  },
  //=================================================
  mounted() {
    document.addEventListener('mousemove', this.onHeaderResize);
    document.addEventListener('mouseup', this.stopHeaderResize);
  },
  //=================================================
}

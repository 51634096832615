import { render, staticRenderFns } from "./BarMenuEditor.vhtml?vue&type=template&id=7b9ad89f&"
import script from "./BarMenuEditor.vue?vue&type=script&lang=js&"
export * from "./BarMenuEditor.vue?vue&type=script&lang=js&"
import style0 from "./BarMenuEditor.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wamp64\\www\\madeyski\\j-php-engine\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b9ad89f')) {
      api.createRecord('7b9ad89f', component.options)
    } else {
      api.reload('7b9ad89f', component.options)
    }
    module.hot.accept("./BarMenuEditor.vhtml?vue&type=template&id=7b9ad89f&", function () {
      api.rerender('7b9ad89f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BarMenuEditor.vue"
export default component.exports
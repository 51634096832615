var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "app-log log-wrapper"
    },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("\r\n    LOG\r\n    "),
        _c("div", { staticClass: "menu" }, [
          _c(
            "a",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  _vm.showArchived = !_vm.showArchived
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-fw fa-hourglass",
                class: _vm.showArchived ? "border" : ""
              })
            ]
          ),
          _vm._v(" "),
          _c("a", { staticClass: "button", on: { click: _vm.close } }, [
            _vm._v("X")
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "log-messages" },
        _vm._l(_vm.visibleMessages, function(message, index) {
          return _c(
            "div",
            {
              key: "log_" + index,
              staticClass: "j-alert",
              class: "j-alert-" + (message.type || "info")
            },
            [
              _c("i", {
                staticClass: "icon fa fa-fw",
                class: "fa-" + (message.icon || "info")
              }),
              _vm._v(" "),
              message._archived
                ? _c("i", { staticClass: "icon fa fa-hourglass fa-fw" })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { domProps: { innerHTML: _vm._s(message.message) } }),
              _vm._v(" "),
              _c("div", { staticClass: "date" }, [
                _vm._v(
                  _vm._s(message.author || "system") +
                    " @ " +
                    _vm._s(message.date || "-")
                )
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }